import { __assign } from "tslib";
import { defineComponent } from 'vue';
import HeadItem from '@/components/content/HeadItem.vue';
// import BaseButton from '@/components/common/BaseButton.vue'
import { mapGetters } from 'vuex';
import { solutionList } from './data';
export default defineComponent({
    name: 'RoadSolution',
    props: {
        id: {
            type: String
        }
    },
    components: {
        HeadItem: HeadItem
        // BaseButton
    },
    data: function () {
        return {
            // 解决方案列表
            solutionList: solutionList,
            // 技术介绍
            techDescList: [
                {
                    id: 1,
                    title: '线控转向系统',
                    content: '元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍',
                    imgSrc: require('../../assets/solution/place.png')
                }, {
                    id: 2,
                    title: '线控转向系统',
                    content: '元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍',
                    imgSrc: require('../../assets/solution/place.png')
                }, {
                    id: 3,
                    title: '线控转向系统',
                    content: '元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍元件特点元件介绍',
                    imgSrc: require('../../assets/solution/place.png')
                }
            ],
            // 应用场景
            sceneList: []
        };
    },
    methods: {
        init: function () {
            var sceneNum = 8;
            var sceneTitle = ['园区', '机场', '公园', '学校', '广场', '步行街', '景区', '高路服务区'];
            for (var i = 0; i < sceneNum; i++) {
                this.sceneList.push({
                    id: i,
                    title: sceneTitle[i],
                    imgSrc: require('../../assets/solution/scene.png'),
                    desc: '该场景下的运作功能'
                });
            }
        }
    },
    mounted: function () {
        // this.init()
    },
    computed: __assign(__assign({}, mapGetters(['isMobile'])), { currentSolution: function () {
            return solutionList[this.id - 1];
        } }),
    watch: {
        id: function () {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });
        }
    }
});
